// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/C2cAccountChanged.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message C2cAccountChanged
 */
export interface C2cAccountChanged {
    /**
     * @generated from protobuf field: repeated C2cAccountChanged.Body list = 1;
     */
    list: C2cAccountChanged_Body[];
}
/**
 * @generated from protobuf message C2cAccountChanged.Body
 */
export interface C2cAccountChanged_Body {
    /**
     * @generated from protobuf field: int32 coinId = 1;
     */
    coinId: number;
    /**
     * @generated from protobuf field: string balance = 2;
     */
    balance: string;
    /**
     * @generated from protobuf field: string freezeBalance = 3;
     */
    freezeBalance: string;
    /**
     * @generated from protobuf field: string merchantFreezeBalance = 4;
     */
    merchantFreezeBalance: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class C2cAccountChanged$Type extends MessageType<C2cAccountChanged> {
    constructor() {
        super("C2cAccountChanged", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => C2cAccountChanged_Body }
        ]);
    }
    create(value?: PartialMessage<C2cAccountChanged>): C2cAccountChanged {
        const message = { list: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<C2cAccountChanged>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: C2cAccountChanged): C2cAccountChanged {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated C2cAccountChanged.Body list */ 1:
                    message.list.push(C2cAccountChanged_Body.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: C2cAccountChanged, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated C2cAccountChanged.Body list = 1; */
        for (let i = 0; i < message.list.length; i++)
            C2cAccountChanged_Body.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message C2cAccountChanged
 */
export const C2cAccountChanged = new C2cAccountChanged$Type();
// @generated message type with reflection information, may provide speed optimized methods
class C2cAccountChanged_Body$Type extends MessageType<C2cAccountChanged_Body> {
    constructor() {
        super("C2cAccountChanged.Body", [
            { no: 1, name: "coinId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "balance", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "freezeBalance", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "merchantFreezeBalance", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<C2cAccountChanged_Body>): C2cAccountChanged_Body {
        const message = { coinId: 0, balance: "", freezeBalance: "", merchantFreezeBalance: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<C2cAccountChanged_Body>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: C2cAccountChanged_Body): C2cAccountChanged_Body {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 coinId */ 1:
                    message.coinId = reader.int32();
                    break;
                case /* string balance */ 2:
                    message.balance = reader.string();
                    break;
                case /* string freezeBalance */ 3:
                    message.freezeBalance = reader.string();
                    break;
                case /* string merchantFreezeBalance */ 4:
                    message.merchantFreezeBalance = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: C2cAccountChanged_Body, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 coinId = 1; */
        if (message.coinId !== 0)
            writer.tag(1, WireType.Varint).int32(message.coinId);
        /* string balance = 2; */
        if (message.balance !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.balance);
        /* string freezeBalance = 3; */
        if (message.freezeBalance !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.freezeBalance);
        /* string merchantFreezeBalance = 4; */
        if (message.merchantFreezeBalance !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.merchantFreezeBalance);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message C2cAccountChanged.Body
 */
export const C2cAccountChanged_Body = new C2cAccountChanged_Body$Type();
