// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/Deal.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * 最新成交
 *
 * @generated from protobuf message Deal
 */
export interface Deal {
    /**
     * @generated from protobuf field: Deal.Body deal = 1;
     */
    deal?: Deal_Body;
}
/**
 * @generated from protobuf message Deal.Body
 */
export interface Deal_Body {
    /**
     * @generated from protobuf field: string price = 1;
     */
    price: string;
    /**
     * @generated from protobuf field: string qty = 2;
     */
    qty: string;
    /**
     * @generated from protobuf field: int64 time = 3;
     */
    time: string;
    /**
     * @generated from protobuf field: int64 id = 4;
     */
    id: string;
    /**
     * @generated from protobuf field: string symbolWassName = 5;
     */
    symbolWassName: string;
    /**
     * 显示颜色 1 卖方颜色 2 买方颜色
     *
     * @generated from protobuf field: int32 direction = 6;
     */
    direction: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Deal$Type extends MessageType<Deal> {
    constructor() {
        super("Deal", [
            { no: 1, name: "deal", kind: "message", T: () => Deal_Body }
        ]);
    }
    create(value?: PartialMessage<Deal>): Deal {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Deal>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Deal): Deal {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Deal.Body deal */ 1:
                    message.deal = Deal_Body.internalBinaryRead(reader, reader.uint32(), options, message.deal);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Deal, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Deal.Body deal = 1; */
        if (message.deal)
            Deal_Body.internalBinaryWrite(message.deal, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Deal
 */
export const Deal = new Deal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Deal_Body$Type extends MessageType<Deal_Body> {
    constructor() {
        super("Deal.Body", [
            { no: 1, name: "price", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "qty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "time", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 4, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 5, name: "symbolWassName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "direction", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Deal_Body>): Deal_Body {
        const message = { price: "", qty: "", time: "0", id: "0", symbolWassName: "", direction: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Deal_Body>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Deal_Body): Deal_Body {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string price */ 1:
                    message.price = reader.string();
                    break;
                case /* string qty */ 2:
                    message.qty = reader.string();
                    break;
                case /* int64 time */ 3:
                    message.time = reader.int64().toString();
                    break;
                case /* int64 id */ 4:
                    message.id = reader.int64().toString();
                    break;
                case /* string symbolWassName */ 5:
                    message.symbolWassName = reader.string();
                    break;
                case /* int32 direction */ 6:
                    message.direction = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Deal_Body, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string price = 1; */
        if (message.price !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.price);
        /* string qty = 2; */
        if (message.qty !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.qty);
        /* int64 time = 3; */
        if (message.time !== "0")
            writer.tag(3, WireType.Varint).int64(message.time);
        /* int64 id = 4; */
        if (message.id !== "0")
            writer.tag(4, WireType.Varint).int64(message.id);
        /* string symbolWassName = 5; */
        if (message.symbolWassName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.symbolWassName);
        /* int32 direction = 6; */
        if (message.direction !== 0)
            writer.tag(6, WireType.Varint).int32(message.direction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Deal.Body
 */
export const Deal_Body = new Deal_Body$Type();
