// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/Notice.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Notice
 */
export interface Notice {
    /**
     * @generated from protobuf field: NoticeType noticeType = 1;
     */
    noticeType: NoticeType;
    /**
     * 消息对应的事件 id
     *
     * @generated from protobuf field: sint64 eventId = 2;
     */
    eventId: string;
    /**
     * 消息产生的时间
     *
     * @generated from protobuf field: sint64 time = 3;
     */
    time: string;
    /**
     * @generated from protobuf oneof: NoticeData
     */
    noticeData: {
        oneofKind: "msgData";
        /**
         * @generated from protobuf field: MsgData msgData = 4;
         */
        msgData: MsgData;
    } | {
        oneofKind: "bizActionData";
        /**
         * @generated from protobuf field: BizActionData bizActionData = 5;
         */
        bizActionData: BizActionData;
    } | {
        oneofKind: "inboxMsgData";
        /**
         * @generated from protobuf field: InboxMsgData inboxMsgData = 6;
         */
        inboxMsgData: InboxMsgData;
    } | {
        oneofKind: undefined;
    };
}
/**
 * 消息，包含 title 和 content
 *
 * @generated from protobuf message MsgData
 */
export interface MsgData {
    /**
     * 消息标题
     *
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * 消息内容
     *
     * @generated from protobuf field: string content = 2;
     */
    content: string;
    /**
     * ios 链接
     *
     * @generated from protobuf field: string iosLink = 3;
     */
    iosLink: string;
    /**
     * android 链接
     *
     * @generated from protobuf field: string androidLink = 4;
     */
    androidLink: string;
    /**
     * web 链接
     *
     * @generated from protobuf field: string webLink = 5;
     */
    webLink: string;
    /**
     * h5 链接
     *
     * @generated from protobuf field: string h5Link = 6;
     */
    h5Link: string;
}
/**
 * @generated from protobuf message BizActionData
 */
export interface BizActionData {
    /**
     * 业务行为
     *
     * @generated from protobuf field: Action action = 1;
     */
    action: Action;
    /**
     * @generated from protobuf field: string latestDeviceNo = 2;
     */
    latestDeviceNo: string; // 最新登录的设备 id
    /**
     * @generated from protobuf field: bool isForceWindow = 3;
     */
    isForceWindow: boolean; // 是否强弹窗 true=强弹窗，false=不强弹窗
    /**
     * @generated from protobuf field: string title = 4;
     */
    title: string; // 消息标题
    /**
     * @generated from protobuf field: string content = 5;
     */
    content: string; // 消息内容
}
/**
 * 站内信消息
 *
 * @generated from protobuf message InboxMsgData
 */
export interface InboxMsgData {
    /**
     * 是否弹窗提醒
     *
     * @generated from protobuf field: bool isRemind = 1;
     */
    isRemind: boolean;
    /**
     * 消息标题
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * 消息内容
     *
     * @generated from protobuf field: string content = 3;
     */
    content: string;
    /**
     * ios 链接
     *
     * @generated from protobuf field: string iosLink = 4;
     */
    iosLink: string;
    /**
     * android 链接
     *
     * @generated from protobuf field: string androidLink = 5;
     */
    androidLink: string;
    /**
     * web 链接
     *
     * @generated from protobuf field: string webLink = 6;
     */
    webLink: string;
    /**
     * h5 链接
     *
     * @generated from protobuf field: string h5Link = 7;
     */
    h5Link: string;
}
/**
 *
 * 通知
 *
 * @generated from protobuf enum NoticeType
 */
export enum NoticeType {
    /**
     * 消息，指包含 title 和 content 的这种类型的消息
     *
     * @generated from protobuf enum value: MSG = 0;
     */
    MSG = 0,
    /**
     * 用于需要各端自己处理逻辑的通知
     *
     * @generated from protobuf enum value: BIZ_ACTION = 1;
     */
    BIZ_ACTION = 1,
    /**
     * 站内信消息
     *
     * @generated from protobuf enum value: INBOX_MSG = 2;
     */
    INBOX_MSG = 2
}
/**
 * @generated from protobuf enum Action
 */
export enum Action {
    /**
     * 多点登录
     *
     * @generated from protobuf enum value: MULTI_LOGIN = 0;
     */
    MULTI_LOGIN = 0,
    /**
     * 更新密码
     *
     * @generated from protobuf enum value: UPDATE_PASSWORD = 1;
     */
    UPDATE_PASSWORD = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Notice$Type extends MessageType<Notice> {
    constructor() {
        super("Notice", [
            { no: 1, name: "noticeType", kind: "enum", T: () => ["NoticeType", NoticeType] },
            { no: 2, name: "eventId", kind: "scalar", T: 18 /*ScalarType.SINT64*/ },
            { no: 3, name: "time", kind: "scalar", T: 18 /*ScalarType.SINT64*/ },
            { no: 4, name: "msgData", kind: "message", oneof: "noticeData", T: () => MsgData },
            { no: 5, name: "bizActionData", kind: "message", oneof: "noticeData", T: () => BizActionData },
            { no: 6, name: "inboxMsgData", kind: "message", oneof: "noticeData", T: () => InboxMsgData }
        ]);
    }
    create(value?: PartialMessage<Notice>): Notice {
        const message = { noticeType: 0, eventId: "0", time: "0", noticeData: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Notice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Notice): Notice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* NoticeType noticeType */ 1:
                    message.noticeType = reader.int32();
                    break;
                case /* sint64 eventId */ 2:
                    message.eventId = reader.sint64().toString();
                    break;
                case /* sint64 time */ 3:
                    message.time = reader.sint64().toString();
                    break;
                case /* MsgData msgData */ 4:
                    message.noticeData = {
                        oneofKind: "msgData",
                        msgData: MsgData.internalBinaryRead(reader, reader.uint32(), options, (message.noticeData as any).msgData)
                    };
                    break;
                case /* BizActionData bizActionData */ 5:
                    message.noticeData = {
                        oneofKind: "bizActionData",
                        bizActionData: BizActionData.internalBinaryRead(reader, reader.uint32(), options, (message.noticeData as any).bizActionData)
                    };
                    break;
                case /* InboxMsgData inboxMsgData */ 6:
                    message.noticeData = {
                        oneofKind: "inboxMsgData",
                        inboxMsgData: InboxMsgData.internalBinaryRead(reader, reader.uint32(), options, (message.noticeData as any).inboxMsgData)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Notice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* NoticeType noticeType = 1; */
        if (message.noticeType !== 0)
            writer.tag(1, WireType.Varint).int32(message.noticeType);
        /* sint64 eventId = 2; */
        if (message.eventId !== "0")
            writer.tag(2, WireType.Varint).sint64(message.eventId);
        /* sint64 time = 3; */
        if (message.time !== "0")
            writer.tag(3, WireType.Varint).sint64(message.time);
        /* MsgData msgData = 4; */
        if (message.noticeData.oneofKind === "msgData")
            MsgData.internalBinaryWrite(message.noticeData.msgData, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* BizActionData bizActionData = 5; */
        if (message.noticeData.oneofKind === "bizActionData")
            BizActionData.internalBinaryWrite(message.noticeData.bizActionData, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* InboxMsgData inboxMsgData = 6; */
        if (message.noticeData.oneofKind === "inboxMsgData")
            InboxMsgData.internalBinaryWrite(message.noticeData.inboxMsgData, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Notice
 */
export const Notice = new Notice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgData$Type extends MessageType<MsgData> {
    constructor() {
        super("MsgData", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "iosLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "androidLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "webLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "h5Link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgData>): MsgData {
        const message = { title: "", content: "", iosLink: "", androidLink: "", webLink: "", h5Link: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MsgData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgData): MsgData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string content */ 2:
                    message.content = reader.string();
                    break;
                case /* string iosLink */ 3:
                    message.iosLink = reader.string();
                    break;
                case /* string androidLink */ 4:
                    message.androidLink = reader.string();
                    break;
                case /* string webLink */ 5:
                    message.webLink = reader.string();
                    break;
                case /* string h5Link */ 6:
                    message.h5Link = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string content = 2; */
        if (message.content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.content);
        /* string iosLink = 3; */
        if (message.iosLink !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.iosLink);
        /* string androidLink = 4; */
        if (message.androidLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.androidLink);
        /* string webLink = 5; */
        if (message.webLink !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.webLink);
        /* string h5Link = 6; */
        if (message.h5Link !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.h5Link);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MsgData
 */
export const MsgData = new MsgData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BizActionData$Type extends MessageType<BizActionData> {
    constructor() {
        super("BizActionData", [
            { no: 1, name: "action", kind: "enum", T: () => ["Action", Action] },
            { no: 2, name: "latestDeviceNo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isForceWindow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BizActionData>): BizActionData {
        const message = { action: 0, latestDeviceNo: "", isForceWindow: false, title: "", content: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BizActionData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BizActionData): BizActionData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Action action */ 1:
                    message.action = reader.int32();
                    break;
                case /* string latestDeviceNo */ 2:
                    message.latestDeviceNo = reader.string();
                    break;
                case /* bool isForceWindow */ 3:
                    message.isForceWindow = reader.bool();
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                case /* string content */ 5:
                    message.content = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BizActionData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Action action = 1; */
        if (message.action !== 0)
            writer.tag(1, WireType.Varint).int32(message.action);
        /* string latestDeviceNo = 2; */
        if (message.latestDeviceNo !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.latestDeviceNo);
        /* bool isForceWindow = 3; */
        if (message.isForceWindow !== false)
            writer.tag(3, WireType.Varint).bool(message.isForceWindow);
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        /* string content = 5; */
        if (message.content !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.content);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BizActionData
 */
export const BizActionData = new BizActionData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InboxMsgData$Type extends MessageType<InboxMsgData> {
    constructor() {
        super("InboxMsgData", [
            { no: 1, name: "isRemind", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "iosLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "androidLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "webLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "h5Link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InboxMsgData>): InboxMsgData {
        const message = { isRemind: false, title: "", content: "", iosLink: "", androidLink: "", webLink: "", h5Link: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InboxMsgData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InboxMsgData): InboxMsgData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isRemind */ 1:
                    message.isRemind = reader.bool();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string content */ 3:
                    message.content = reader.string();
                    break;
                case /* string iosLink */ 4:
                    message.iosLink = reader.string();
                    break;
                case /* string androidLink */ 5:
                    message.androidLink = reader.string();
                    break;
                case /* string webLink */ 6:
                    message.webLink = reader.string();
                    break;
                case /* string h5Link */ 7:
                    message.h5Link = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InboxMsgData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isRemind = 1; */
        if (message.isRemind !== false)
            writer.tag(1, WireType.Varint).bool(message.isRemind);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string content = 3; */
        if (message.content !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.content);
        /* string iosLink = 4; */
        if (message.iosLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.iosLink);
        /* string androidLink = 5; */
        if (message.androidLink !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.androidLink);
        /* string webLink = 6; */
        if (message.webLink !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.webLink);
        /* string h5Link = 7; */
        if (message.h5Link !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.h5Link);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InboxMsgData
 */
export const InboxMsgData = new InboxMsgData$Type();
