// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/Asset.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Asset
 */
export interface Asset {
    /**
     * @generated from protobuf field: Asset.Body asset = 1;
     */
    asset?: Asset_Body;
}
/**
 * @generated from protobuf message Asset.Body
 */
export interface Asset_Body {
    /**
     * @generated from protobuf field: sint64 uid = 1;
     */
    uid: string;
    /**
     * @generated from protobuf field: sint64 coinId = 2;
     */
    coinId: string;
    /**
     * @generated from protobuf field: sint64 businessId = 3;
     */
    businessId: string;
    /**
     * @generated from protobuf field: string balance = 4;
     */
    balance: string;
    /**
     * @generated from protobuf field: string locked = 5;
     */
    locked: string;
    /**
     * @generated from protobuf field: string total = 6;
     */
    total: string;
    /**
     * @generated from protobuf field: bool isRefresh = 7;
     */
    isRefresh: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class Asset$Type extends MessageType<Asset> {
    constructor() {
        super("Asset", [
            { no: 1, name: "asset", kind: "message", T: () => Asset_Body }
        ]);
    }
    create(value?: PartialMessage<Asset>): Asset {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Asset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Asset): Asset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Asset.Body asset */ 1:
                    message.asset = Asset_Body.internalBinaryRead(reader, reader.uint32(), options, message.asset);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Asset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Asset.Body asset = 1; */
        if (message.asset)
            Asset_Body.internalBinaryWrite(message.asset, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Asset
 */
export const Asset = new Asset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Asset_Body$Type extends MessageType<Asset_Body> {
    constructor() {
        super("Asset.Body", [
            { no: 1, name: "uid", kind: "scalar", T: 18 /*ScalarType.SINT64*/ },
            { no: 2, name: "coinId", kind: "scalar", T: 18 /*ScalarType.SINT64*/ },
            { no: 3, name: "businessId", kind: "scalar", T: 18 /*ScalarType.SINT64*/ },
            { no: 4, name: "balance", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "locked", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "total", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "isRefresh", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Asset_Body>): Asset_Body {
        const message = { uid: "0", coinId: "0", businessId: "0", balance: "", locked: "", total: "", isRefresh: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Asset_Body>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Asset_Body): Asset_Body {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint64 uid */ 1:
                    message.uid = reader.sint64().toString();
                    break;
                case /* sint64 coinId */ 2:
                    message.coinId = reader.sint64().toString();
                    break;
                case /* sint64 businessId */ 3:
                    message.businessId = reader.sint64().toString();
                    break;
                case /* string balance */ 4:
                    message.balance = reader.string();
                    break;
                case /* string locked */ 5:
                    message.locked = reader.string();
                    break;
                case /* string total */ 6:
                    message.total = reader.string();
                    break;
                case /* bool isRefresh */ 7:
                    message.isRefresh = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Asset_Body, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint64 uid = 1; */
        if (message.uid !== "0")
            writer.tag(1, WireType.Varint).sint64(message.uid);
        /* sint64 coinId = 2; */
        if (message.coinId !== "0")
            writer.tag(2, WireType.Varint).sint64(message.coinId);
        /* sint64 businessId = 3; */
        if (message.businessId !== "0")
            writer.tag(3, WireType.Varint).sint64(message.businessId);
        /* string balance = 4; */
        if (message.balance !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.balance);
        /* string locked = 5; */
        if (message.locked !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.locked);
        /* string total = 6; */
        if (message.total !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.total);
        /* bool isRefresh = 7; */
        if (message.isRefresh !== false)
            writer.tag(7, WireType.Varint).bool(message.isRefresh);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Asset.Body
 */
export const Asset_Body = new Asset_Body$Type();
