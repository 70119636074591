// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/ConceptPrice.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message ConceptPrice
 */
export interface ConceptPrice {
    /**
     * @generated from protobuf field: repeated ConceptPrice.Body list = 1;
     */
    list: ConceptPrice_Body[];
}
/**
 * @generated from protobuf message ConceptPrice.Body
 */
export interface ConceptPrice_Body {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string chg = 2;
     */
    chg: string;
    /**
     * @generated from protobuf field: string marketValue = 3;
     */
    marketValue: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ConceptPrice$Type extends MessageType<ConceptPrice> {
    constructor() {
        super("ConceptPrice", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ConceptPrice_Body }
        ]);
    }
    create(value?: PartialMessage<ConceptPrice>): ConceptPrice {
        const message = { list: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptPrice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptPrice): ConceptPrice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ConceptPrice.Body list */ 1:
                    message.list.push(ConceptPrice_Body.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptPrice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ConceptPrice.Body list = 1; */
        for (let i = 0; i < message.list.length; i++)
            ConceptPrice_Body.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ConceptPrice
 */
export const ConceptPrice = new ConceptPrice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptPrice_Body$Type extends MessageType<ConceptPrice_Body> {
    constructor() {
        super("ConceptPrice.Body", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "chg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "marketValue", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConceptPrice_Body>): ConceptPrice_Body {
        const message = { id: 0, chg: "", marketValue: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptPrice_Body>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptPrice_Body): ConceptPrice_Body {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string chg */ 2:
                    message.chg = reader.string();
                    break;
                case /* string marketValue */ 3:
                    message.marketValue = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptPrice_Body, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string chg = 2; */
        if (message.chg !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.chg);
        /* string marketValue = 3; */
        if (message.marketValue !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.marketValue);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ConceptPrice.Body
 */
export const ConceptPrice_Body = new ConceptPrice_Body$Type();
