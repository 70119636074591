// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/Response.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../google/protobuf/any";
/**
 * @generated from protobuf message Response
 */
export interface Response {
    /**
     * @generated from protobuf field: string biz = 1;
     */
    biz: string; // 业务 spot/perpetual    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string; // 类型 depth/deal/asset..    /**
     * @generated from protobuf field: string base = 3;
     */
    base: string; // 交易币种 btc/ltc/eth    /**
     * @generated from protobuf field: string quote = 4;
     */
    quote: string; // 计价币种 cny/usd/btc    /**
     * @generated from protobuf field: string contractCode = 5;
     */
    contractCode: string; // 交易币种 p_btc-usdt    /**
     * @generated from protobuf field: string granularity = 6;
     */
    granularity: string; // 时间周期 1min/ 5min 等    /**
     * @generated from protobuf field: google.protobuf.Any data = 7;
     */
    data?: Any;
    /**
     * @generated from protobuf field: int64 userId = 8;
     */
    userId: string;
}
/**
 * @generated from protobuf message CommonRsp
 */
export interface CommonRsp {
    /**
     * @generated from protobuf field: int32 code = 1;
     */
    code: number;
    /**
     * @generated from protobuf field: string msg = 2;
     */
    msg: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Response$Type extends MessageType<Response> {
    constructor() {
        super("Response", [
            { no: 1, name: "biz", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "base", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "quote", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "contractCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "granularity", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "data", kind: "message", T: () => Any },
            { no: 8, name: "userId", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value?: PartialMessage<Response>): Response {
        const message = { biz: "", type: "", base: "", quote: "", contractCode: "", granularity: "", userId: "0" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Response>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Response): Response {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string biz */ 1:
                    message.biz = reader.string();
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* string base */ 3:
                    message.base = reader.string();
                    break;
                case /* string quote */ 4:
                    message.quote = reader.string();
                    break;
                case /* string contractCode */ 5:
                    message.contractCode = reader.string();
                    break;
                case /* string granularity */ 6:
                    message.granularity = reader.string();
                    break;
                case /* google.protobuf.Any data */ 7:
                    message.data = Any.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* int64 userId */ 8:
                    message.userId = reader.int64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Response, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string biz = 1; */
        if (message.biz !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.biz);
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* string base = 3; */
        if (message.base !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.base);
        /* string quote = 4; */
        if (message.quote !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.quote);
        /* string contractCode = 5; */
        if (message.contractCode !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.contractCode);
        /* string granularity = 6; */
        if (message.granularity !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.granularity);
        /* google.protobuf.Any data = 7; */
        if (message.data)
            Any.internalBinaryWrite(message.data, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int64 userId = 8; */
        if (message.userId !== "0")
            writer.tag(8, WireType.Varint).int64(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Response
 */
export const Response = new Response$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommonRsp$Type extends MessageType<CommonRsp> {
    constructor() {
        super("CommonRsp", [
            { no: 1, name: "code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "msg", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CommonRsp>): CommonRsp {
        const message = { code: 0, msg: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CommonRsp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CommonRsp): CommonRsp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 code */ 1:
                    message.code = reader.int32();
                    break;
                case /* string msg */ 2:
                    message.msg = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CommonRsp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* string msg = 2; */
        if (message.msg !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.msg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CommonRsp
 */
export const CommonRsp = new CommonRsp$Type();
