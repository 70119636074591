// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/Rate.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Rate
 */
export interface Rate {
    /**
     * @generated from protobuf field: repeated Rate.CoinRate coinRate = 1;
     */
    coinRate: Rate_CoinRate[]; // 虚拟币
    /**
     * @generated from protobuf field: repeated Rate.LegalCurrencyRate legalCurrencyRate = 2;
     */
    legalCurrencyRate: Rate_LegalCurrencyRate[]; // 法币
}
/**
 * @generated from protobuf message Rate.CoinRate
 */
export interface Rate_CoinRate {
    /**
     * @generated from protobuf field: sint64 coinId = 1;
     */
    coinId: string; // 币种 id
    /**
     * @generated from protobuf field: string symbol = 2;
     */
    symbol: string; // 币种符号
    /**
     * @generated from protobuf field: int32 coinPrecision = 3;
     */
    coinPrecision: number; // 币种精度
    /**
     * @generated from protobuf field: string usdtRate = 4;
     */
    usdtRate: string; // 币种对 USDT 汇率
}
/**
 * @generated from protobuf message Rate.LegalCurrencyRate
 */
export interface Rate_LegalCurrencyRate {
    /**
     * @generated from protobuf field: string currency = 1;
     */
    currency: string; // 法币
    /**
     * @generated from protobuf field: string rate = 2;
     */
    rate: string; // 法币汇率
}
// @generated message type with reflection information, may provide speed optimized methods
class Rate$Type extends MessageType<Rate> {
    constructor() {
        super("Rate", [
            { no: 1, name: "coinRate", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Rate_CoinRate },
            { no: 2, name: "legalCurrencyRate", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Rate_LegalCurrencyRate }
        ]);
    }
    create(value?: PartialMessage<Rate>): Rate {
        const message = { coinRate: [], legalCurrencyRate: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Rate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Rate): Rate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Rate.CoinRate coinRate */ 1:
                    message.coinRate.push(Rate_CoinRate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Rate.LegalCurrencyRate legalCurrencyRate */ 2:
                    message.legalCurrencyRate.push(Rate_LegalCurrencyRate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Rate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Rate.CoinRate coinRate = 1; */
        for (let i = 0; i < message.coinRate.length; i++)
            Rate_CoinRate.internalBinaryWrite(message.coinRate[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Rate.LegalCurrencyRate legalCurrencyRate = 2; */
        for (let i = 0; i < message.legalCurrencyRate.length; i++)
            Rate_LegalCurrencyRate.internalBinaryWrite(message.legalCurrencyRate[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Rate
 */
export const Rate = new Rate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Rate_CoinRate$Type extends MessageType<Rate_CoinRate> {
    constructor() {
        super("Rate.CoinRate", [
            { no: 1, name: "coinId", kind: "scalar", T: 18 /*ScalarType.SINT64*/ },
            { no: 2, name: "symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "coinPrecision", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "usdtRate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Rate_CoinRate>): Rate_CoinRate {
        const message = { coinId: "0", symbol: "", coinPrecision: 0, usdtRate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Rate_CoinRate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Rate_CoinRate): Rate_CoinRate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint64 coinId */ 1:
                    message.coinId = reader.sint64().toString();
                    break;
                case /* string symbol */ 2:
                    message.symbol = reader.string();
                    break;
                case /* int32 coinPrecision */ 3:
                    message.coinPrecision = reader.int32();
                    break;
                case /* string usdtRate */ 4:
                    message.usdtRate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Rate_CoinRate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint64 coinId = 1; */
        if (message.coinId !== "0")
            writer.tag(1, WireType.Varint).sint64(message.coinId);
        /* string symbol = 2; */
        if (message.symbol !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.symbol);
        /* int32 coinPrecision = 3; */
        if (message.coinPrecision !== 0)
            writer.tag(3, WireType.Varint).int32(message.coinPrecision);
        /* string usdtRate = 4; */
        if (message.usdtRate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.usdtRate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Rate.CoinRate
 */
export const Rate_CoinRate = new Rate_CoinRate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Rate_LegalCurrencyRate$Type extends MessageType<Rate_LegalCurrencyRate> {
    constructor() {
        super("Rate.LegalCurrencyRate", [
            { no: 1, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Rate_LegalCurrencyRate>): Rate_LegalCurrencyRate {
        const message = { currency: "", rate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Rate_LegalCurrencyRate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Rate_LegalCurrencyRate): Rate_LegalCurrencyRate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string currency */ 1:
                    message.currency = reader.string();
                    break;
                case /* string rate */ 2:
                    message.rate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Rate_LegalCurrencyRate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string currency = 1; */
        if (message.currency !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.currency);
        /* string rate = 2; */
        if (message.rate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Rate.LegalCurrencyRate
 */
export const Rate_LegalCurrencyRate = new Rate_LegalCurrencyRate$Type();
