// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/PerpetualGroupDetails.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message PerpetualGroupDetail
 */
export interface PerpetualGroupDetail {
    /**
     * @generated from protobuf field: PerpetualGroupDetail.Detail detail = 1;
     */
    detail?: PerpetualGroupDetail_Detail; // 合约组详情
    /**
     * @generated from protobuf field: repeated PerpetualGroupDetail.Positions positions = 2;
     */
    positions: PerpetualGroupDetail_Positions[]; // 合约组持仓列表
}
/**
 * @generated from protobuf message PerpetualGroupDetail.Detail
 */
export interface PerpetualGroupDetail_Detail {
    /**
     * @generated from protobuf field: string groupAsset = 1;
     */
    groupAsset: string; // 合约组总价值
    /**
     * @generated from protobuf field: string marginAvailable = 2;
     */
    marginAvailable: string; // 合约组可用保证金
    /**
     * @generated from protobuf field: string positionMargin = 3;
     */
    positionMargin: string; // 仓位保证金
    /**
     * @generated from protobuf field: repeated PerpetualGroupDetail.MarginCoin marginCoin = 4;
     */
    marginCoin: PerpetualGroupDetail_MarginCoin[]; // 保证金占比
    /**
     * @generated from protobuf field: repeated PerpetualGroupDetail.PositionAsset positionAsset = 5;
     */
    positionAsset: PerpetualGroupDetail_PositionAsset[]; // 持仓风险占比
    /**
     * @generated from protobuf field: string groupId = 6;
     */
    groupId: string; // 合约组 ID
    /**
     * @generated from protobuf field: string baseCoin = 7;
     */
    baseCoin: string; // 计价币
    /**
     * @generated from protobuf field: string groupName = 8;
     */
    groupName: string; // 合约组名称
    /**
     * @generated from protobuf field: string availableBalanceValue = 9;
     */
    availableBalanceValue: string; // 现货资产购买力
}
/**
 * @generated from protobuf message PerpetualGroupDetail.MarginCoin
 */
export interface PerpetualGroupDetail_MarginCoin {
    /**
     * @generated from protobuf field: string coinName = 1;
     */
    coinName: string; // 币种名称
    /**
     * @generated from protobuf field: string coinConvert = 2;
     */
    coinConvert: string; // 折算价值
}
/**
 * @generated from protobuf message PerpetualGroupDetail.PositionAsset
 */
export interface PerpetualGroupDetail_PositionAsset {
    /**
     * @generated from protobuf field: string groupId = 1;
     */
    groupId: string; // 合约组 ID
    /**
     * @generated from protobuf field: string groupName = 2;
     */
    groupName: string; // 合约组名称
    /**
     * @generated from protobuf field: string baseCoin = 3;
     */
    baseCoin: string; // 计价币
}
/**
 * @generated from protobuf message PerpetualGroupDetail.Positions
 */
export interface PerpetualGroupDetail_Positions {
    /**
     * @generated from protobuf field: string groupId = 1;
     */
    groupId: string; // 合约组 id
    /**
     * @generated from protobuf field: string groupName = 2;
     */
    groupName: string; // 合约组名称
    /**
     * @generated from protobuf field: string groupMargin = 3;
     */
    groupMargin: string; // 合约组保证金
    /**
     * @generated from protobuf field: string tradeId = 4;
     */
    tradeId: string; // 交易对 id
    /**
     * @generated from protobuf field: string symbol = 5;
     */
    symbol: string; // 交易对名称
    /**
     * @generated from protobuf field: string baseSymbolName = 6;
     */
    baseSymbolName: string; // 基础币
    /**
     * @generated from protobuf field: string quoteSymbolName = 7;
     */
    quoteSymbolName: string; // 计价币
    /**
     * @generated from protobuf field: string typeInd = 8;
     */
    typeInd: string; // 合约类型
    /**
     * @generated from protobuf field: string positionId = 9;
     */
    positionId: string; // 仓位 id
    /**
     * @generated from protobuf field: string sideInd = 10;
     */
    sideInd: string; // long:多仓; short:空仓
    /**
     * @generated from protobuf field: string lever = 11;
     */
    lever: string; // 杠杆倍数
    /**
     * @generated from protobuf field: string unrealizedProfit = 12;
     */
    unrealizedProfit: string; // 未实现盈亏
    /**
     * @generated from protobuf field: string profitRatio = 13;
     */
    profitRatio: string; // 收益率
    /**
     * @generated from protobuf field: string size = 14;
     */
    size: string; // 持仓数量
    /**
     * @generated from protobuf field: string marginRatio = 15;
     */
    marginRatio: string; // 仓位保证金率
    /**
     * @generated from protobuf field: string maintMarginRatio = 16;
     */
    maintMarginRatio: string; // 维持保证金率
    /**
     * @generated from protobuf field: string openPrice = 17;
     */
    openPrice: string; // 开仓均价
    /**
     * @generated from protobuf field: string markPrice = 18;
     */
    markPrice: string; // 标记价格
    /**
     * @generated from protobuf field: string latestPrice = 19;
     */
    latestPrice: string; // 最新价
    /**
     * @generated from protobuf field: string liquidatePrice = 20;
     */
    liquidatePrice: string; // 预估强平价
    /**
     * @generated from protobuf field: string realizedProfit = 21;
     */
    realizedProfit: string; // 已实现盈亏
    /**
     * @generated from protobuf field: string profit = 22;
     */
    profit: string; // 收益
    /**
     * @generated from protobuf field: string statusCd = 23;
     */
    statusCd: string; // 仓位状态：opened:已开仓，closed:已关闭，locked:锁仓中
    /**
     * @generated from protobuf field: string lockSize = 24;
     */
    lockSize: string; // 锁仓数量
    /**
     * @generated from protobuf field: string lockFees = 25;
     */
    lockFees: string; // 锁仓利息
    /**
     * @generated from protobuf field: string amountOffset = 26;
     */
    amountOffset: string; // 数量精度
    /**
     * @generated from protobuf field: string priceOffset = 27;
     */
    priceOffset: string; // 价格精度
    /**
     * @generated from protobuf field: string createdByTime = 28;
     */
    createdByTime: string; // 创建时间
    /**
     * @generated from protobuf field: string updatedByTime = 29;
     */
    updatedByTime: string; // 修改时间
    /**
     * @generated from protobuf field: PerpetualGroupDetail.LockRecord lockRecord = 30;
     */
    lockRecord?: PerpetualGroupDetail_LockRecord; // 锁仓记录
    /**
     * @generated from protobuf field: repeated PerpetualGroupDetail.ProfitLossEntrust profitLossEntrust = 31;
     */
    profitLossEntrust: PerpetualGroupDetail_ProfitLossEntrust[]; // 仓位止盈止损
    /**
     * @generated from protobuf field: string entrustFrozenSize = 32;
     */
    entrustFrozenSize: string; // 委托冻结的数量
}
/**
 * @generated from protobuf message PerpetualGroupDetail.LockRecord
 */
export interface PerpetualGroupDetail_LockRecord {
    /**
     * @generated from protobuf field: string lockPrice = 1;
     */
    lockPrice: string; // 首次锁仓价格
    /**
     * @generated from protobuf field: string fees = 2;
     */
    fees: string; // 锁仓手续费总和
}
/**
 * @generated from protobuf message PerpetualGroupDetail.ProfitLossEntrust
 */
export interface PerpetualGroupDetail_ProfitLossEntrust {
    /**
     * @generated from protobuf field: string strategyTypeInd = 1;
     */
    strategyTypeInd: string; // 策略类型 stop_profit 止盈 stop_loss 止损
    /**
     * @generated from protobuf field: string triggerPrice = 2;
     */
    triggerPrice: string; // 触发价格
    /**
     * @generated from protobuf field: string triggerPriceTypeInd = 3;
     */
    triggerPriceTypeInd: string; // 触发方式：mark 标记，new 最新
    /**
     * @generated from protobuf field: string id = 4;
     */
    id: string; // 仓位止盈止损 id
}
// @generated message type with reflection information, may provide speed optimized methods
class PerpetualGroupDetail$Type extends MessageType<PerpetualGroupDetail> {
    constructor() {
        super("PerpetualGroupDetail", [
            { no: 1, name: "detail", kind: "message", T: () => PerpetualGroupDetail_Detail },
            { no: 2, name: "positions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerpetualGroupDetail_Positions }
        ]);
    }
    create(value?: PartialMessage<PerpetualGroupDetail>): PerpetualGroupDetail {
        const message = { positions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerpetualGroupDetail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerpetualGroupDetail): PerpetualGroupDetail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PerpetualGroupDetail.Detail detail */ 1:
                    message.detail = PerpetualGroupDetail_Detail.internalBinaryRead(reader, reader.uint32(), options, message.detail);
                    break;
                case /* repeated PerpetualGroupDetail.Positions positions */ 2:
                    message.positions.push(PerpetualGroupDetail_Positions.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PerpetualGroupDetail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PerpetualGroupDetail.Detail detail = 1; */
        if (message.detail)
            PerpetualGroupDetail_Detail.internalBinaryWrite(message.detail, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated PerpetualGroupDetail.Positions positions = 2; */
        for (let i = 0; i < message.positions.length; i++)
            PerpetualGroupDetail_Positions.internalBinaryWrite(message.positions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerpetualGroupDetail
 */
export const PerpetualGroupDetail = new PerpetualGroupDetail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerpetualGroupDetail_Detail$Type extends MessageType<PerpetualGroupDetail_Detail> {
    constructor() {
        super("PerpetualGroupDetail.Detail", [
            { no: 1, name: "groupAsset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "marginAvailable", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "positionMargin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "marginCoin", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerpetualGroupDetail_MarginCoin },
            { no: 5, name: "positionAsset", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerpetualGroupDetail_PositionAsset },
            { no: 6, name: "groupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "baseCoin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "groupName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "availableBalanceValue", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PerpetualGroupDetail_Detail>): PerpetualGroupDetail_Detail {
        const message = { groupAsset: "", marginAvailable: "", positionMargin: "", marginCoin: [], positionAsset: [], groupId: "", baseCoin: "", groupName: "", availableBalanceValue: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerpetualGroupDetail_Detail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerpetualGroupDetail_Detail): PerpetualGroupDetail_Detail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string groupAsset */ 1:
                    message.groupAsset = reader.string();
                    break;
                case /* string marginAvailable */ 2:
                    message.marginAvailable = reader.string();
                    break;
                case /* string positionMargin */ 3:
                    message.positionMargin = reader.string();
                    break;
                case /* repeated PerpetualGroupDetail.MarginCoin marginCoin */ 4:
                    message.marginCoin.push(PerpetualGroupDetail_MarginCoin.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated PerpetualGroupDetail.PositionAsset positionAsset */ 5:
                    message.positionAsset.push(PerpetualGroupDetail_PositionAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string groupId */ 6:
                    message.groupId = reader.string();
                    break;
                case /* string baseCoin */ 7:
                    message.baseCoin = reader.string();
                    break;
                case /* string groupName */ 8:
                    message.groupName = reader.string();
                    break;
                case /* string availableBalanceValue */ 9:
                    message.availableBalanceValue = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PerpetualGroupDetail_Detail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string groupAsset = 1; */
        if (message.groupAsset !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.groupAsset);
        /* string marginAvailable = 2; */
        if (message.marginAvailable !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.marginAvailable);
        /* string positionMargin = 3; */
        if (message.positionMargin !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.positionMargin);
        /* repeated PerpetualGroupDetail.MarginCoin marginCoin = 4; */
        for (let i = 0; i < message.marginCoin.length; i++)
            PerpetualGroupDetail_MarginCoin.internalBinaryWrite(message.marginCoin[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated PerpetualGroupDetail.PositionAsset positionAsset = 5; */
        for (let i = 0; i < message.positionAsset.length; i++)
            PerpetualGroupDetail_PositionAsset.internalBinaryWrite(message.positionAsset[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string groupId = 6; */
        if (message.groupId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.groupId);
        /* string baseCoin = 7; */
        if (message.baseCoin !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.baseCoin);
        /* string groupName = 8; */
        if (message.groupName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.groupName);
        /* string availableBalanceValue = 9; */
        if (message.availableBalanceValue !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.availableBalanceValue);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerpetualGroupDetail.Detail
 */
export const PerpetualGroupDetail_Detail = new PerpetualGroupDetail_Detail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerpetualGroupDetail_MarginCoin$Type extends MessageType<PerpetualGroupDetail_MarginCoin> {
    constructor() {
        super("PerpetualGroupDetail.MarginCoin", [
            { no: 1, name: "coinName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "coinConvert", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PerpetualGroupDetail_MarginCoin>): PerpetualGroupDetail_MarginCoin {
        const message = { coinName: "", coinConvert: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerpetualGroupDetail_MarginCoin>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerpetualGroupDetail_MarginCoin): PerpetualGroupDetail_MarginCoin {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string coinName */ 1:
                    message.coinName = reader.string();
                    break;
                case /* string coinConvert */ 2:
                    message.coinConvert = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PerpetualGroupDetail_MarginCoin, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string coinName = 1; */
        if (message.coinName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.coinName);
        /* string coinConvert = 2; */
        if (message.coinConvert !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.coinConvert);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerpetualGroupDetail.MarginCoin
 */
export const PerpetualGroupDetail_MarginCoin = new PerpetualGroupDetail_MarginCoin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerpetualGroupDetail_PositionAsset$Type extends MessageType<PerpetualGroupDetail_PositionAsset> {
    constructor() {
        super("PerpetualGroupDetail.PositionAsset", [
            { no: 1, name: "groupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "groupName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "baseCoin", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PerpetualGroupDetail_PositionAsset>): PerpetualGroupDetail_PositionAsset {
        const message = { groupId: "", groupName: "", baseCoin: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerpetualGroupDetail_PositionAsset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerpetualGroupDetail_PositionAsset): PerpetualGroupDetail_PositionAsset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string groupId */ 1:
                    message.groupId = reader.string();
                    break;
                case /* string groupName */ 2:
                    message.groupName = reader.string();
                    break;
                case /* string baseCoin */ 3:
                    message.baseCoin = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PerpetualGroupDetail_PositionAsset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string groupId = 1; */
        if (message.groupId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.groupId);
        /* string groupName = 2; */
        if (message.groupName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.groupName);
        /* string baseCoin = 3; */
        if (message.baseCoin !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.baseCoin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerpetualGroupDetail.PositionAsset
 */
export const PerpetualGroupDetail_PositionAsset = new PerpetualGroupDetail_PositionAsset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerpetualGroupDetail_Positions$Type extends MessageType<PerpetualGroupDetail_Positions> {
    constructor() {
        super("PerpetualGroupDetail.Positions", [
            { no: 1, name: "groupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "groupName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "groupMargin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tradeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "baseSymbolName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "quoteSymbolName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "typeInd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "positionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "sideInd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "lever", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "unrealizedProfit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "profitRatio", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "size", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "marginRatio", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "maintMarginRatio", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "openPrice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "markPrice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "latestPrice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "liquidatePrice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "realizedProfit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "profit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "statusCd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "lockSize", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "lockFees", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "amountOffset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "priceOffset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "createdByTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "updatedByTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "lockRecord", kind: "message", T: () => PerpetualGroupDetail_LockRecord },
            { no: 31, name: "profitLossEntrust", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerpetualGroupDetail_ProfitLossEntrust },
            { no: 32, name: "entrustFrozenSize", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PerpetualGroupDetail_Positions>): PerpetualGroupDetail_Positions {
        const message = { groupId: "", groupName: "", groupMargin: "", tradeId: "", symbol: "", baseSymbolName: "", quoteSymbolName: "", typeInd: "", positionId: "", sideInd: "", lever: "", unrealizedProfit: "", profitRatio: "", size: "", marginRatio: "", maintMarginRatio: "", openPrice: "", markPrice: "", latestPrice: "", liquidatePrice: "", realizedProfit: "", profit: "", statusCd: "", lockSize: "", lockFees: "", amountOffset: "", priceOffset: "", createdByTime: "", updatedByTime: "", profitLossEntrust: [], entrustFrozenSize: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerpetualGroupDetail_Positions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerpetualGroupDetail_Positions): PerpetualGroupDetail_Positions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string groupId */ 1:
                    message.groupId = reader.string();
                    break;
                case /* string groupName */ 2:
                    message.groupName = reader.string();
                    break;
                case /* string groupMargin */ 3:
                    message.groupMargin = reader.string();
                    break;
                case /* string tradeId */ 4:
                    message.tradeId = reader.string();
                    break;
                case /* string symbol */ 5:
                    message.symbol = reader.string();
                    break;
                case /* string baseSymbolName */ 6:
                    message.baseSymbolName = reader.string();
                    break;
                case /* string quoteSymbolName */ 7:
                    message.quoteSymbolName = reader.string();
                    break;
                case /* string typeInd */ 8:
                    message.typeInd = reader.string();
                    break;
                case /* string positionId */ 9:
                    message.positionId = reader.string();
                    break;
                case /* string sideInd */ 10:
                    message.sideInd = reader.string();
                    break;
                case /* string lever */ 11:
                    message.lever = reader.string();
                    break;
                case /* string unrealizedProfit */ 12:
                    message.unrealizedProfit = reader.string();
                    break;
                case /* string profitRatio */ 13:
                    message.profitRatio = reader.string();
                    break;
                case /* string size */ 14:
                    message.size = reader.string();
                    break;
                case /* string marginRatio */ 15:
                    message.marginRatio = reader.string();
                    break;
                case /* string maintMarginRatio */ 16:
                    message.maintMarginRatio = reader.string();
                    break;
                case /* string openPrice */ 17:
                    message.openPrice = reader.string();
                    break;
                case /* string markPrice */ 18:
                    message.markPrice = reader.string();
                    break;
                case /* string latestPrice */ 19:
                    message.latestPrice = reader.string();
                    break;
                case /* string liquidatePrice */ 20:
                    message.liquidatePrice = reader.string();
                    break;
                case /* string realizedProfit */ 21:
                    message.realizedProfit = reader.string();
                    break;
                case /* string profit */ 22:
                    message.profit = reader.string();
                    break;
                case /* string statusCd */ 23:
                    message.statusCd = reader.string();
                    break;
                case /* string lockSize */ 24:
                    message.lockSize = reader.string();
                    break;
                case /* string lockFees */ 25:
                    message.lockFees = reader.string();
                    break;
                case /* string amountOffset */ 26:
                    message.amountOffset = reader.string();
                    break;
                case /* string priceOffset */ 27:
                    message.priceOffset = reader.string();
                    break;
                case /* string createdByTime */ 28:
                    message.createdByTime = reader.string();
                    break;
                case /* string updatedByTime */ 29:
                    message.updatedByTime = reader.string();
                    break;
                case /* PerpetualGroupDetail.LockRecord lockRecord */ 30:
                    message.lockRecord = PerpetualGroupDetail_LockRecord.internalBinaryRead(reader, reader.uint32(), options, message.lockRecord);
                    break;
                case /* repeated PerpetualGroupDetail.ProfitLossEntrust profitLossEntrust */ 31:
                    message.profitLossEntrust.push(PerpetualGroupDetail_ProfitLossEntrust.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string entrustFrozenSize */ 32:
                    message.entrustFrozenSize = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PerpetualGroupDetail_Positions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string groupId = 1; */
        if (message.groupId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.groupId);
        /* string groupName = 2; */
        if (message.groupName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.groupName);
        /* string groupMargin = 3; */
        if (message.groupMargin !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.groupMargin);
        /* string tradeId = 4; */
        if (message.tradeId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tradeId);
        /* string symbol = 5; */
        if (message.symbol !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.symbol);
        /* string baseSymbolName = 6; */
        if (message.baseSymbolName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.baseSymbolName);
        /* string quoteSymbolName = 7; */
        if (message.quoteSymbolName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.quoteSymbolName);
        /* string typeInd = 8; */
        if (message.typeInd !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.typeInd);
        /* string positionId = 9; */
        if (message.positionId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.positionId);
        /* string sideInd = 10; */
        if (message.sideInd !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.sideInd);
        /* string lever = 11; */
        if (message.lever !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.lever);
        /* string unrealizedProfit = 12; */
        if (message.unrealizedProfit !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.unrealizedProfit);
        /* string profitRatio = 13; */
        if (message.profitRatio !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.profitRatio);
        /* string size = 14; */
        if (message.size !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.size);
        /* string marginRatio = 15; */
        if (message.marginRatio !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.marginRatio);
        /* string maintMarginRatio = 16; */
        if (message.maintMarginRatio !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.maintMarginRatio);
        /* string openPrice = 17; */
        if (message.openPrice !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.openPrice);
        /* string markPrice = 18; */
        if (message.markPrice !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.markPrice);
        /* string latestPrice = 19; */
        if (message.latestPrice !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.latestPrice);
        /* string liquidatePrice = 20; */
        if (message.liquidatePrice !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.liquidatePrice);
        /* string realizedProfit = 21; */
        if (message.realizedProfit !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.realizedProfit);
        /* string profit = 22; */
        if (message.profit !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.profit);
        /* string statusCd = 23; */
        if (message.statusCd !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.statusCd);
        /* string lockSize = 24; */
        if (message.lockSize !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.lockSize);
        /* string lockFees = 25; */
        if (message.lockFees !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.lockFees);
        /* string amountOffset = 26; */
        if (message.amountOffset !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.amountOffset);
        /* string priceOffset = 27; */
        if (message.priceOffset !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.priceOffset);
        /* string createdByTime = 28; */
        if (message.createdByTime !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.createdByTime);
        /* string updatedByTime = 29; */
        if (message.updatedByTime !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.updatedByTime);
        /* PerpetualGroupDetail.LockRecord lockRecord = 30; */
        if (message.lockRecord)
            PerpetualGroupDetail_LockRecord.internalBinaryWrite(message.lockRecord, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* repeated PerpetualGroupDetail.ProfitLossEntrust profitLossEntrust = 31; */
        for (let i = 0; i < message.profitLossEntrust.length; i++)
            PerpetualGroupDetail_ProfitLossEntrust.internalBinaryWrite(message.profitLossEntrust[i], writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* string entrustFrozenSize = 32; */
        if (message.entrustFrozenSize !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.entrustFrozenSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerpetualGroupDetail.Positions
 */
export const PerpetualGroupDetail_Positions = new PerpetualGroupDetail_Positions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerpetualGroupDetail_LockRecord$Type extends MessageType<PerpetualGroupDetail_LockRecord> {
    constructor() {
        super("PerpetualGroupDetail.LockRecord", [
            { no: 1, name: "lockPrice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "fees", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PerpetualGroupDetail_LockRecord>): PerpetualGroupDetail_LockRecord {
        const message = { lockPrice: "", fees: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerpetualGroupDetail_LockRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerpetualGroupDetail_LockRecord): PerpetualGroupDetail_LockRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string lockPrice */ 1:
                    message.lockPrice = reader.string();
                    break;
                case /* string fees */ 2:
                    message.fees = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PerpetualGroupDetail_LockRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string lockPrice = 1; */
        if (message.lockPrice !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.lockPrice);
        /* string fees = 2; */
        if (message.fees !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fees);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerpetualGroupDetail.LockRecord
 */
export const PerpetualGroupDetail_LockRecord = new PerpetualGroupDetail_LockRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerpetualGroupDetail_ProfitLossEntrust$Type extends MessageType<PerpetualGroupDetail_ProfitLossEntrust> {
    constructor() {
        super("PerpetualGroupDetail.ProfitLossEntrust", [
            { no: 1, name: "strategyTypeInd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "triggerPrice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "triggerPriceTypeInd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PerpetualGroupDetail_ProfitLossEntrust>): PerpetualGroupDetail_ProfitLossEntrust {
        const message = { strategyTypeInd: "", triggerPrice: "", triggerPriceTypeInd: "", id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerpetualGroupDetail_ProfitLossEntrust>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerpetualGroupDetail_ProfitLossEntrust): PerpetualGroupDetail_ProfitLossEntrust {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string strategyTypeInd */ 1:
                    message.strategyTypeInd = reader.string();
                    break;
                case /* string triggerPrice */ 2:
                    message.triggerPrice = reader.string();
                    break;
                case /* string triggerPriceTypeInd */ 3:
                    message.triggerPriceTypeInd = reader.string();
                    break;
                case /* string id */ 4:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PerpetualGroupDetail_ProfitLossEntrust, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string strategyTypeInd = 1; */
        if (message.strategyTypeInd !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.strategyTypeInd);
        /* string triggerPrice = 2; */
        if (message.triggerPrice !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.triggerPrice);
        /* string triggerPriceTypeInd = 3; */
        if (message.triggerPriceTypeInd !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.triggerPriceTypeInd);
        /* string id = 4; */
        if (message.id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerpetualGroupDetail.ProfitLossEntrust
 */
export const PerpetualGroupDetail_ProfitLossEntrust = new PerpetualGroupDetail_ProfitLossEntrust$Type();
