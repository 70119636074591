// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/Depth.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * 深度
 *
 * @generated from protobuf message Depth
 */
export interface Depth {
    /**
     * @generated from protobuf field: repeated Depth.Data bids = 1;
     */
    bids: Depth_Data[];
    /**
     * @generated from protobuf field: repeated Depth.Data asks = 2;
     */
    asks: Depth_Data[];
    /**
     * @generated from protobuf field: string time = 3;
     */
    time: string;
    /**
     * @generated from protobuf field: string symbolWassName = 4;
     */
    symbolWassName: string;
}
/**
 * @generated from protobuf message Depth.Data
 */
export interface Depth_Data {
    /**
     * @generated from protobuf field: string price = 1;
     */
    price: string;
    /**
     * @generated from protobuf field: string volume = 2;
     */
    volume: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Depth$Type extends MessageType<Depth> {
    constructor() {
        super("Depth", [
            { no: 1, name: "bids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Depth_Data },
            { no: 2, name: "asks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Depth_Data },
            { no: 3, name: "time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "symbolWassName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Depth>): Depth {
        const message = { bids: [], asks: [], time: "", symbolWassName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Depth>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Depth): Depth {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Depth.Data bids */ 1:
                    message.bids.push(Depth_Data.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Depth.Data asks */ 2:
                    message.asks.push(Depth_Data.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string time */ 3:
                    message.time = reader.string();
                    break;
                case /* string symbolWassName */ 4:
                    message.symbolWassName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Depth, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Depth.Data bids = 1; */
        for (let i = 0; i < message.bids.length; i++)
            Depth_Data.internalBinaryWrite(message.bids[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Depth.Data asks = 2; */
        for (let i = 0; i < message.asks.length; i++)
            Depth_Data.internalBinaryWrite(message.asks[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string time = 3; */
        if (message.time !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.time);
        /* string symbolWassName = 4; */
        if (message.symbolWassName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.symbolWassName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Depth
 */
export const Depth = new Depth$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Depth_Data$Type extends MessageType<Depth_Data> {
    constructor() {
        super("Depth.Data", [
            { no: 1, name: "price", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "volume", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Depth_Data>): Depth_Data {
        const message = { price: "", volume: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Depth_Data>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Depth_Data): Depth_Data {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string price */ 1:
                    message.price = reader.string();
                    break;
                case /* string volume */ 2:
                    message.volume = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Depth_Data, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string price = 1; */
        if (message.price !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.price);
        /* string volume = 2; */
        if (message.volume !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.volume);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Depth.Data
 */
export const Depth_Data = new Depth_Data$Type();
